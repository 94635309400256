
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Button from "primevue/button";

export default defineComponent({
  name: 'NotFound',
  components: {
    Button
  },
  setup() {
    const message = ref('');
    const fullMessage = "Pero no te preocupes, siempre puedes volver a la página de inicio.";
    const timeoutId = ref(0);

    const router = useRouter();

    const goHome = () => {
      router.push({ name: 'Home' });
    };

    onMounted(() => {
      let i = 0;
      const type = () => {
        message.value += fullMessage[i];
        i++;
        if (i === fullMessage.length) {
          clearTimeout(timeoutId.value);
        } else {
          timeoutId.value = setTimeout(type, 50);
        }
      };
      type();
    });

    return {
      goHome,
      message,
    };
  }
});
